import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import PageError from '@aurora/shared-client/components/error/PageError/PageError';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import Icons from '@aurora/shared-client/public/static/graphics/processed/enums';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import {
  ModerationAction,
  ModerationStatus
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  type EndUserComponent,
  type EndUserPages,
  EndUserQueryParams
} from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import endUserComponentRegistry from '../../../features/endUserComponentRegistry';
import { contextMessageToMessageActionMenu } from '../../../helpers/messages/MessageHelper/MessageHelper';
import { MessageActionMenuItem } from '../../../types/enums';
import type {
  ContextMessageQuery,
  ContextMessageQueryVariables
} from '../../../types/graphql-types';
import messageQuery from '../../context/ContextMessage.query.graphql';
import { MessageActionMenuIcon } from '../../messages/MessageActionMenu/MessageActionMenu';
import MessageActionToolbar from '../../messages/MessageActionToolbar/MessageActionToolbar';
import MessageBanner from '../../messages/MessageBanner/MessageBanner';
import { useIsPreviewMode } from '../../messages/useCurrentOrPreviewMessage';
import useRedirectOnArchivedMessage from '../../useRedirectOnArchivedMessage';
import useTranslation from '../../useTranslation';
import BasePage from '../BasePage/BasePage';
import type { FirstSectionComponents } from '../Quilt/QuiltContainerRenderer';
import { useMessagePageSeo } from './useMessagePageSeo';

const MessageActionMenu = dynamic(
  () => import('../../messages/MessageActionMenu/MessageActionMenu'),
  {
    ssr: false
  }
);

const MessageSubscriptionAction = dynamic(
  () => import('../../messages/MessageSubscriptionAction/MessageSubscriptionAction'),
  {
    ssr: false
  }
);

interface Props {
  /**
   * current page id
   */
  pageId: EndUserPages;

  /**
   * twitter card type for seo
   */
  twitterCardType?: string;
}

/**
 * Component used as the root component in the various message pages. Component
 * provides common aspects of the various message pages, including the message page
 * action menu and handling when the message does not exist.
 *
 * @author Stephen McLaughry, Adam Ayres
 */
const MessagePage: React.FC<React.PropsWithChildren<Props>> = ({ pageId, twitterCardType }) => {
  const cx = useClassNameMapper();
  const pageComponent: EndUserComponent = endUserComponentRegistry.getComponentIdForPage(pageId);
  const { formatMessage, loading: textLoading } = useTranslation(pageComponent);
  let nextSeoProps = useMessagePageSeo(pageComponent);
  nextSeoProps = { ...nextSeoProps, twitter: { cardType: twitterCardType } };
  const { contextMessage: contextMessageData, contextMessageArchivalData } = useContext(AppContext);
  const isPreview: boolean = useIsPreviewMode();
  const { router } = useEndUserRoutes();
  const articleIdParam = router.getUnwrappedQueryParam(EndUserQueryParams.ARTICLE_ID);

  /**
   * Redirects the user to related url if they attempt to access an archived message
   */
  useRedirectOnArchivedMessage();

  /*
   * When loading a message from guide or manual sorting list on KB article page without updating the context message object,
   * explicitly supply the actual context message for message-related operations.
   * The query will only be triggered when an article ID is present in the query parameters.
   */
  const { data: messageData, loading: messageQueryLoading } = useQueryWithTracing<
    ContextMessageQuery,
    ContextMessageQueryVariables
  >(module, messageQuery, {
    variables: {
      id: articleIdParam
    },
    skip: !articleIdParam
  });

  const contextMessage =
    !messageQueryLoading && articleIdParam ? messageData?.message : contextMessageData;

  const canModerateMessage: boolean =
    contextMessage?.messagePolicies?.canModerateSpamMessage.failureReason === null;

  const { rejectReason, status } = contextMessage?.moderationData || {};
  const isHamMessage: boolean =
    rejectReason === ModerationAction.Spam && status === ModerationStatus.Rejected;

  const shouldDisplayPageErrorForBaseUserWhenMessageArchived: boolean =
    !contextMessage && contextMessageArchivalData?.archived;

  if (shouldDisplayPageErrorForBaseUserWhenMessageArchived) {
    return (
      <PageError
        icon={Icons.ArchivedIcon}
        statusCode={404}
        title={formatMessage('archivedMessageTitle')}
      />
    );
  }

  if (
    !contextMessage ||
    (!canModerateMessage && isHamMessage) ||
    (!isPreview && contextMessage?.latestVersion?.major === '0')
  ) {
    return <PageError statusCode={403} title={formatMessage('errorMissing')} />;
  }

  /**
   * Renders the message action menu.
   */
  function renderMessageActionMenu(): React.ReactElement {
    return (
      <MessageActionMenu
        items={[
          { item: MessageActionMenuItem.REPORT_ABUSE },
          { item: MessageActionMenuItem.MARK_AS_APPROVED },
          { item: MessageActionMenuItem.REJECT },
          { item: MessageActionMenuItem.MARK_AS_NOT_ABUSE },
          { item: MessageActionMenuItem.MARK_AS_NOT_SPAM },
          { item: MessageActionMenuItem.BAN_MEMBER },
          { item: MessageActionMenuItem.BLOCK_EDITS },
          { item: MessageActionMenuItem.TURN_OFF_COMMENTS }
        ]}
        adminItems={[
          { item: MessageActionMenuItem.UPDATE_STATUS },
          { item: MessageActionMenuItem.EDIT },
          { item: MessageActionMenuItem.MOVE },
          { item: MessageActionMenuItem.ESCALATE_TO_SALESFORCE },
          { item: MessageActionMenuItem.ARTICLE_HISTORY },
          { item: MessageActionMenuItem.COPY_PUBLISHED_URL },
          { item: MessageActionMenuItem.INVITE },
          { item: MessageActionMenuItem.ARCHIVE },
          { item: MessageActionMenuItem.UNARCHIVE },
          { item: MessageActionMenuItem.RELATED_CONTENT_URL },
          { item: MessageActionMenuItem.DELETE },
          { item: MessageActionMenuItem.DELETE_DRAFT }
        ]}
        message={contextMessageToMessageActionMenu(contextMessage)}
        className={cx('lia-g-ml-10')}
        iconColor={IconColor.GRAY_900}
        iconSize={IconSize.PX_20}
        icon={MessageActionMenuIcon.GEAR}
      />
    );
  }

  /**
   * Renders the message subscription action.
   */
  function renderMessageSubscriptionAction(): React.ReactElement {
    return <MessageSubscriptionAction message={contextMessage} />;
  }

  const componentsToRenderFirstInFirstVisibleSection: FirstSectionComponents = {
    components: [MessageActionToolbar, MessageBanner],
    attachFirstComponentToTop: true
  };

  return (
    <BasePage
      showLoading={textLoading}
      pageId={pageId}
      breadcrumbActionComponents={[renderMessageSubscriptionAction, renderMessageActionMenu]}
      nextSeoProps={nextSeoProps}
      componentsToRenderFirstInFirstVisibleSection={componentsToRenderFirstInFirstVisibleSection}
    />
  );
};

export default MessagePage;
