import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import ThemedContainer from '@aurora/shared-client/components/common/ThemedContainer/ThemedContainer';
import type { AppContextInterface } from '@aurora/shared-client/components/context/AppContext/AppContext';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import { canViewSpamDashBoard } from '@aurora/shared-client/helpers/nodes/NodePolicyHelper';
import { isNodeForum } from '@aurora/shared-client/helpers/nodes/NodeUtils';
import Icons from '@aurora/shared-client/icons';
import type {
  ManageContentPageAndParams,
  MessagePageOrReplyPageAndParams,
  MessagePagesAndParams
} from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import {
  ModerationAction,
  ModerationStatus,
  VisibilityScope
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { TopicMessage, Board } from '@aurora/shared-generated/types/graphql-schema-types';
import {
  EndUserComponent,
  EndUserPages,
  EndUserQueryParams
} from '@aurora/shared-types/pages/enums';
import UrlBuilder from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlBuilder';
import React, { useContext, useEffect, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import ActionFeedbacks from '../../common/ActionFeedback/ActionFeedbackTypes';
import EditContext from '../../context/EditContext/EditContext';
import useTranslation from '../../useTranslation';
import { MessageListTabItem } from '../MessageListTabs/MessageListTabs';
import localStyles from './MessageBanner.module.pcss';
import useDateTime from '@aurora/shared-client/components/useDateTime';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import ConversationStyleBehaviorHelper from '../../../helpers/boards/ConversationStyleBehaviorHelper';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import messageLinkQuery from '../MessageLink.query.graphql';
import useSeoProperties from '@aurora/shared-client/components/seo/useSeoProperties';
import type { MessageLinkQuery, MessageLinkQueryVariables } from '../../../types/graphql-types';

interface Props {
  /**
   * The class name(s) applied to the component element.
   */
  className?: string;
}

/**
 * Message Banner.
 * @author Aditi Agarwal
 */
const MessageBanner: React.FC<React.PropsWithChildren<Props>> = ({ className }) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_BANNER_WIDGET
  );
  const { quilt } = useContext(EditContext);
  const { contextMessage } = useContext<AppContextInterface>(AppContext);
  const tenant = useContext(TenantContext);
  const {
    publicConfig: { quiltsV2Enabled }
  } = tenant;
  const { router, Link } = useEndUserRoutes();
  const { getCaseSensitivePath } = useSeoProperties();
  const { data: nodePoliciesData, loading: nodePoliciesLoading } = useNodePolicies(module, {
    useCanViewSpamDashBoard: true,
    useCanArchiveMessage: true
  });

  const { formatAbsoluteDateTime } = useDateTime();
  const { rejectReason, status } = contextMessage?.moderationData || {};
  const isRejectReasonSpam = rejectReason === ModerationAction.Spam;
  const archivedMessageIdFromUrl = router.getUnwrappedQueryParam(
    EndUserQueryParams.ARCHIVED_MESSAGE_ID,
    null
  );
  const [archivedMessageId, setArchivedMessageId] = useState<string>(null);
  const isArchived = contextMessage?.visibilityScope === VisibilityScope.Archived;
  const archivalData = isArchived && (contextMessage as TopicMessage)?.archivalData;

  //This useeffect is used to save the archived message id query param value before removing it from url
  useEffect(() => {
    if (archivedMessageIdFromUrl) {
      setArchivedMessageId(archivedMessageIdFromUrl);
      router.removeQueryParam([EndUserQueryParams.ARCHIVED_MESSAGE_ID], true);
    }
  }, [archivedMessageIdFromUrl, router]);

  const { data: messageData, loading: messageQueryLoading } = useQueryWithTracing<
    MessageLinkQuery,
    MessageLinkQueryVariables
  >(module, messageLinkQuery, {
    variables: {
      id: `message:${archivedMessageId}`
    },
    skip: !archivedMessageId
  });

  if (textLoading || nodePoliciesLoading || quilt || messageQueryLoading) {
    return null;
  }

  const canViewArchivedContent = checkPolicy(
    (nodePoliciesData?.coreNode as Board)?.boardPolicies?.canArchiveMessage
  );
  const archivedMessage = messageData?.message;
  const willRender: boolean =
    !!archivedMessage ||
    isArchived ||
    ((isRejectReasonSpam || rejectReason === 'ABUSE' || rejectReason === 'OTHER') &&
      status === ModerationStatus.Rejected);

  const queryParams = {
    [EndUserQueryParams.TAB]: router.getUnwrappedQueryParam(
      EndUserQueryParams.TAB,
      isRejectReasonSpam ? MessageListTabItem.SPAM : MessageListTabItem.ABUSE
    )
  };

  /**
   * Renders the link to spam dashboard.
   */
  function renderSpamDashboardLink(): React.ReactElement {
    return (
      canViewSpamDashBoard(nodePoliciesData?.coreNode) && (
        <div className={cx('lia-message-link')}>
          <Link<ManageContentPageAndParams>
            route={EndUserPages.ManageContentPage}
            query={queryParams}
            legacyBehavior={true}
          >
            {formatMessage('manageSpam')}
          </Link>
        </div>
      )
    );
  }

  /**
   * Renders the link to related content in an archived message page
   */
  function renderRelatedContentLink(): React.ReactElement {
    const url = archivalData?.suggestedUrl;
    const urlDomain = new URL(url).origin;
    if (tenant.host === urlDomain) {
      const finalRoute = router.getRouteAndParamsByPath(url);
      const { route, params, query } = finalRoute;
      const isMessagePage = [
        EndUserPages.ForumMessagePage,
        EndUserPages.BlogMessagePage,
        EndUserPages.TkbMessagePage,
        EndUserPages.IdeaMessagePage,
        EndUserPages.OccasionMessagePage
      ].includes(route);
      const { messagePage } = ConversationStyleBehaviorHelper.getInstance(contextMessage.board);
      const relativeUrlForRoute = router.getRelativeUrlForRoute<MessagePageOrReplyPageAndParams>(
        messagePage,
        {
          boardId: contextMessage.board.displayId,
          messageSubject: UrlHelper.determineSlugForMessagePath(contextMessage),
          messageId: contextMessage.uid.toString()
        }
      );
      const archivedUrl = UrlBuilder.fromUrl(tenant.baseUrl).addPath(relativeUrlForRoute).build();
      const archivedMessageQueryParams = !isMessagePage
        ? {
            ...query,
            [EndUserQueryParams.ACTION_FEEDBACK]: ActionFeedbacks.REDIRECT_TO_RELATED_PAGE,
            [EndUserQueryParams.TIMESTAMP]: Date.now().toString(),
            [EndUserQueryParams.ARCHIVED_MESSAGE_URL]: archivedUrl
          }
        : {
            [EndUserQueryParams.ARCHIVED_MESSAGE_ID]: contextMessage.uid.toString()
          };

      return (
        <div className={cx('lia-message-link')}>
          <Link
            route={route}
            params={params}
            query={archivedMessageQueryParams}
            legacyBehavior={true}
          >
            {formatMessage('relatedUrl')}
          </Link>
        </div>
      );
    } else {
      return (
        <div className={cx('lia-message-link')}>
          <a href={url}>{formatMessage('relatedUrl')}</a>
        </div>
      );
    }
  }

  /**
   * Renders the link to archived message in related content page
   */
  function renderArchivedContentLink(): React.ReactElement {
    if (archivedMessage.visibilityScope === VisibilityScope.Archived) {
      const { messagePage } = ConversationStyleBehaviorHelper.getInstance(archivedMessage.board);
      const messageParams: MessagePagesAndParams['params'] = {
        boardId: getCaseSensitivePath(archivedMessage.board.displayId),
        messageSubject: UrlHelper.determineSlugForMessagePath({
          subject: archivedMessage?.subject
        }),
        messageId: archivedMessageId
      };

      return (
        <div className={cx('lia-message-link')}>
          <Link<MessagePageOrReplyPageAndParams>
            route={messagePage}
            params={messageParams}
            query={{
              [EndUserQueryParams.ARCHIVED_CONTENT]: 'true'
            }}
            legacyBehavior={true}
          >
            {formatMessage('archivedContentLink')}
          </Link>
        </div>
      );
    }
  }

  /**
   * Renders the banner for message when marked as spam.
   */
  function renderBannerForMessage(): React.ReactElement {
    return (
      <ThemedContainer>
        <div
          className={cx(
            'lia-message-status-container',
            // TODO: remove this check as part of the quilts v2 config cleanup
            `lia-message-status-container${quiltsV2Enabled ? '-v2' : ''}-${
              isNodeForum(contextMessage?.board) ? 'forum' : 'message'
            }`,
            className
          )}
          data-testid="MessageBanner"
        >
          <span className={cx('lia-g-mr-10')}>
            <Icon icon={Icons.FlagIcon} color={IconColor.WARNING} size={IconSize.PX_16} />
          </span>
          <div>
            {canViewArchivedContent && archivedMessage
              ? formatMessage('relatedContentText')
              : canViewArchivedContent && isArchived
              ? formatMessage('messageArchived', {
                  date: formatAbsoluteDateTime(archivalData?.archivedDate)
                })
              : formatMessage(
                  isRejectReasonSpam
                    ? 'messageMarkedAsSpam'
                    : rejectReason === 'ABUSE'
                    ? 'messageMarkedAsAbuse'
                    : 'messageMarkedAsOther'
                )}
            {isRejectReasonSpam && renderSpamDashboardLink()}
            {canViewArchivedContent &&
              isArchived &&
              archivalData?.suggestedUrl &&
              renderRelatedContentLink()}
            {canViewArchivedContent && archivedMessage && renderArchivedContentLink()}
          </div>
        </div>
      </ThemedContainer>
    );
  }

  return willRender && renderBannerForMessage();
};

export default MessageBanner;
